import $ from 'jquery';
import { gsap } from 'gsap';
import { throttle } from 'throttle-debounce';
import { SplitText } from 'gsap/SplitText';
import { $Elements, Animations, Breakpoints, Classes, Events } from '../global/constants';
import { dateAnimation, elementPosition } from '../global/helpers';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Split text ----------

	$('.simple-text-section .content p').each(function() {
		Animations.mobile.min.push(() => {
			let splitText = new SplitText(this, {
				type: "lines"
			});

			gsap.from(splitText.lines, {
				y: '100%',
				opacity: 0,
				duration: 0.8,
				ease: 'power1.out',
				stagger: 0.1,
				scrollTrigger: {
					trigger: this,
					start: 'top bottom',
				}
			})
		})
	});

	$('.quote-section .quote, h2, h1').each(function() {
		const element = this;

		Animations.mobile.min.push(() => {
			if(element) {
				let splitText = new SplitText(element, {
					type: "words"
				});

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: element,
						start: 'top 80%',
					}
				});

				timeline.from(splitText.words, {
					y: '100%',
					opacity: 0,
					duration: 0.6,
					ease: 'power1.out',
					stagger: 0.1,
				});

				timeline.add(() => {
					$(element).closest('.animated-section').addClass(Classes.active);
				});
			}
		});
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone), .slider-single-wine .informations-column, .slider-single-wine .image-column').each(function() {
		Animations.mobile.min.push(() => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Animated Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {
				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	// ---------- Animated fullscreen-image-section ----------

	$('.scroll-effect').each(function() {

		Animations.mobile.min.push(() => {
			const content = this.querySelector('.image-column');
			const contentMainImage = this.querySelector('.column-image');
			const mainImage = this.querySelector('.column-image .image');

			if(content && mainImage && contentMainImage) {

				const timeline = gsap.timeline({
					defaults: {
						ease: "power1.inOut",
						force3D: !0
					},
					scrollTrigger: {
						trigger: this,
						start: 'top bottom+=75%',
						end: 'bottom bottom',
						scrub: !0,
						invalidateOnRefresh: true,
					},
				});

				const getDimensions = (element) => element.getBoundingClientRect();
				const calculateScale = (image) => {
					const windowWidth = window.innerWidth;
					const imageDimension = getDimensions(image)
					const widthScale = windowWidth / imageDimension.width;
					return widthScale;
				};

				timeline.to(content,
					{ scale: () => calculateScale(contentMainImage) },
					0
				);

				timeline.to(mainImage, { scale: 1 }, 0);

			}
		})
	});

	//---------- Pagination Slider Fade-out-down ----------

	$('.single-wine .aside').each(function() {
		Animations.global.push(() => {
			const aside = $(this);

			if(aside) {
				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: () => $('.slider-single-wine'),
						start: 'top bottom',
						end: 'bottom bottom',
						onLeave: () => {
							aside.addClass(Classes.fadeDown)
						},
						onEnterBack: () => {
							aside.removeClass(Classes.fadeDown)
						},
					}
				});
			}
		});
	});


	// ---------- Animated visits-slider-section ---------

	$('.visits-slider-section').each(function() {

		Animations.global.push(() => {
			const content = this.querySelector(':scope .section-columns');

			if(content) {
				const columns = gsap.utils.toArray('.content-column', content);
				const contentWidth = () => (columns.reduce((val, column) => val + column.offsetWidth, 0)) + Math.max(0, window.innerWidth - Breakpoints.xxl);
				const triggerOffset = () => {
					const position = elementPosition(content);
					return position.top;
				}

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						pin: true,
						scrub: true,
						invalidateOnRefresh: true,
						start: () => triggerOffset(),
						end: () => '+=' + contentWidth(),
					},
				});

				timeline.to(content, { x: () => window.innerWidth - contentWidth(), ease: "none" });
			}
		});
	});

	//---------- Date posts section - double border ----------
	$('.date-posts-section').each(function() {
		let section = this;
		let lastProgress = 0;
		let previousHeight = section.offsetHeight;

		Animations.mobile.min.push(() => {
			function createDateAnimation() {
				gsap.to(document.body, {
					scrollTrigger: {
						trigger: section,
						start: 'top 90%',
						end: () => `+=${section.offsetHeight}`,
						onEnter: () => {
						},
						onUpdate: (self) => {
							let progress = self.progress;
							if(self.direction === 1 && progress >= lastProgress) {
								section.style.setProperty('--scale-y', progress);
								lastProgress = progress;
							}

							let currentHeight = section.offsetHeight;
							if(currentHeight !== previousHeight) {
								previousHeight = currentHeight;
								self.kill();
								createDateAnimation();
							}
						},
						onLeave: () => {
							section.classList.add('line-active');
							section.style.setProperty('--scale-y', 1);
							lastProgress = 0;
						},
						onRefresh: (self) => {
							section.style.setProperty('--scale-y', self.progress);
							lastProgress = 0;

							if(self.progress === 1) {
								section.classList.add('line-active');
							}
						}
					},
				});
			}

			ScrollTrigger.refresh();
			dateAnimation(section);
			createDateAnimation();
		});
	});


	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: 10,
					end: () => 'max',
					onUpdate: throttle(200, (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}),
				}
			});
		});
	});

};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());

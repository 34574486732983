import $ from 'jquery';
import StoreLocator from 'store-locator';

import { $Elements, Events, MapTiles } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	if(typeof store_locator_params !== 'undefined') {

		$('.store-locator-section .store-locator').each(async function() {

			const data = new FormData();
			data.append('action', store_locator_params.action);
			data.append('spreadsheet', this.dataset.spreadsheet);
			JSON.parse(this.dataset.ranges).map(range => data.append('ranges[]', range));

			try {

				const response = await ajax({
					url: store_locator_params.url,
					type: 'POST',
					dataType: 'json',
					data: data,
					processData: false,
					contentType: false,
				});

				if(response.data.stores) {

					const storeLocator = new StoreLocator({
						stores: response.data.stores,
						map: {
							tiles: MapTiles,
							markers: {
								popup: feature => L.popup().setContent(feature.properties.popup),
								icon: feature => L.icon({
									iconUrl: feature.properties.icon,
									iconSize: [44, 60],
									iconAnchor: [22, 60],
									popupAnchor: [0, -60],
								}),
							}
						}
					});
				}
			}
			catch(e) {
			}
		});
	}
});

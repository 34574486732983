import { $Elements, Events } from './constants';

$Elements.document.on(Events.page.enter, async() => {
	const filtersActive = {
		colour: null,
		designation: null,
		region: null
	};

	const wines = Array.from(document.querySelectorAll('.wines-item'));
	const winesListContainer = document.querySelector('.result');

	function updateWineItems() {
		wines.forEach((item) => {
			const colour = item.getAttribute('data-colour');
			const designation = item.getAttribute('data-designation');
			const region = item.getAttribute('data-region');

			const colourActive = !filtersActive.colour || filtersActive.colour === colour;
			const designationActive = !filtersActive.designation || filtersActive.designation === designation;
			const regionActive = !filtersActive.region || filtersActive.region === region;

			const shouldBeVisible = colourActive && designationActive && regionActive;
			item.classList.toggle('hidden', !shouldBeVisible);
		});

		updateButtonAndOptionsStates();
		createNewColumn();
	}

	function handleButtonClick(type, value, filter) {
		if(filtersActive[type] === value || value === 'all') {
			filtersActive[type] = null;
			const clickButton = document.querySelector(filter + ` [data-filter="${value}"]`);

			if(clickButton) {
				clickButton.classList.remove('active');
			}
		}
		else {
			filtersActive[type] = value;

			const buttons = document.querySelectorAll(filter + ' .button');
			if(buttons) {
				buttons.forEach(btn => btn.classList.remove('active'));
			}

			const clickButton = document.querySelector(filter + ` [data-filter="${value}"]`);
			if(clickButton) {
				clickButton.classList.add('active');
			}
		}

		updateWineItems();
	}

	function updateButtonAndOptionsStates() {
		const itemsActive = wines.filter(item => !item.classList.contains('hidden'));

		if(!filtersActive.colour) {
			const availableColours = new Set(itemsActive.map(item => item.getAttribute('data-colour')));
			const colourButtons = document.querySelectorAll('.filter-colour .button');
			const colourSelects = document.querySelectorAll('select.filter-colour option');

			colourButtons.forEach(button => {
				const colour = button.getAttribute('data-filter');
				if(availableColours.has(colour)) {
					button.classList.remove('no-active');
				}
				else {
					button.classList.add('no-active');
				}
			});

			colourSelects.forEach(option => {
				const colour = option.value;
				if(availableColours.has(colour) || colour === "") {
					option.disabled = false;
				}
				else {
					option.disabled = true;
				}
			});
		}

		if(!filtersActive.designation) {
			const availableDesignations = new Set(itemsActive.map(item => item.getAttribute('data-designation')));
			const designationButtons = document.querySelectorAll('.filter-designation .button');
			const designationSelects = document.querySelectorAll('select.filter-designation option');

			designationButtons.forEach(button => {
				const designation = button.getAttribute('data-filter');
				if(availableDesignations.has(designation)) {
					button.classList.remove('no-active');
				}
				else {
					button.classList.add('no-active');
				}
			});

			designationSelects.forEach(option => {
				const designation = option.value;
				if(availableDesignations.has(designation) || designation === "") {
					option.disabled = false;
				}
				else {
					option.disabled = true;
				}
			});
		}

		if(!filtersActive.region) {
			const availableRegions = new Set(itemsActive.map(item => item.getAttribute('data-region')));
			const regionButtons = document.querySelectorAll('.filter-region .button');
			const regionSelects = document.querySelectorAll('select.filter-region option');

			regionButtons.forEach(button => {
				const region = button.getAttribute('data-filter');
				if(availableRegions.has(region)) {
					button.classList.remove('no-active');
				}
				else {
					button.classList.add('no-active');
				}
			});

			regionSelects.forEach(option => {
				const region = option.value;
				if(availableRegions.has(region) || region === "") {
					option.disabled = false;
				}
				else {
					option.disabled = true;
				}
			});
		}
	}


	function updateSelectStates(select) {
		select.addEventListener('focus', function() {
			this.parentElement.classList.add('active');
		});

		select.addEventListener('blur', function() {
			this.parentElement.classList.remove('active');
		});
	}

	function createNewColumn() {
		const itemsActive = wines.filter(item => !item.classList.contains('hidden'));

		winesListContainer.innerHTML = '';

		const columns = 3;
		const itemsPerColumn = Math.ceil(itemsActive.length / columns);
		for(let i = 0; i < columns; i++) {
			const columnItems = itemsActive.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn);
			const ul = document.createElement('ul');
			ul.classList.add('wines-list');
			columnItems.forEach(item => ul.appendChild(item));
			winesListContainer.appendChild(ul);
		}
	}

	const colourButtons = document.querySelectorAll('.filter-colour .button');
	colourButtons.forEach(button => {
		button.addEventListener('click', function() {
			const colour = this.getAttribute('data-filter');
			handleButtonClick('colour', colour, '.filter-colour');
		});
	});

	const designationButtons = document.querySelectorAll('.filter-designation .button');
	designationButtons.forEach(button => {
		button.addEventListener('click', function() {
			const designation = this.getAttribute('data-filter');
			handleButtonClick('designation', designation, '.filter-designation');
		});
	});

	const regionButtons = document.querySelectorAll('.filter-region .button');
	regionButtons.forEach(button => {
		button.addEventListener('click', function() {
			const region = this.getAttribute('data-filter');
			handleButtonClick('region', region, '.filter-region');
		});
	});


	const colourSelects = document.querySelectorAll('select.filter-colour');
	colourSelects.forEach(select => {
		updateSelectStates(select)

		select.addEventListener('change', function() {
			const colour = this.value;
			handleButtonClick('colour', colour, '.filter-colour');
		});
	});

	const designationSelects = document.querySelectorAll('select.filter-designation');
	designationSelects.forEach(select => {
		updateSelectStates(select)

		select.addEventListener('change', function() {
			const designation = this.value;
			handleButtonClick('designation', designation, '.filter-designation');
		});
	});

	const regionSelects = document.querySelectorAll('select.filter-region');
	regionSelects.forEach(select => {
		updateSelectStates(select)

		select.addEventListener('change', function() {
			const region = this.value;

			handleButtonClick('region', region, '.filter-region');
		});
	});
});

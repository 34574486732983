import $ from 'jquery';

import { $Elements, Classes, Events } from './constants';
import { disableBodyScroll, enableBodyScroll, smoothScrollTo } from './helpers';
import getDocumentElement from '@popperjs/core/lib/dom-utils/getDocumentElement';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------


	$Elements.PageWrapper.on('click', '.menu-toggle, a[href="#menu-wrapper"], .menu-overlay', function(e) {
		e.preventDefault();

		if($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll();
		}
		else {
			$Elements.body.addClass(Classes.menuOpen);

			disableBodyScroll();
		}
	});

	$Elements.PageWrapper.on('click', '.navbar-menu a, .menu-wrapper a', function(e) {

		const linkTarget = this.getAttribute('href');
		const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

		if(sectionTarget.substring(0, 1) === '#') {

			e.preventDefault();

			const section = $(sectionTarget);

			if(section.length) {

				window.history.replaceState(null, null, sectionTarget);

				$Elements.body.removeClass(Classes.menuOpen);
				enableBodyScroll()
				smoothScrollTo(section);
			}
			else if(sectionTarget !== '#') {
				window.location = linkTarget;
			}
		}
	});
});

$Elements.document.on(Events.page.loaded, () => {

	const linkTarget = window.location.href;
	const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

	if(sectionTarget.substring(0, 1) === '#') {

		const section = $(sectionTarget);

		if(section.length) {
			smoothScrollTo(section, 0);
		}
	}
});

import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Controller, Navigation, Pagination } from 'swiper/modules';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Durations, Events, Classes } from './constants';
import GLightbox from 'glightbox';
import { dateAnimation } from './helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Slider for Articles ----------

	$('.other-posts-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: true,
				grabCursor: true,
				watchSlidesProgress: true,
				centerInsufficientSlides: true,
				slideToClickedSlide: true,
				slidesPerView: 'auto',
			});
		}
	});

	// ---------- slider-single-wine Carousel ----------

	$('.slider-single-wine .section-carousel').each(function() {

		const carousel = this;
		const vintages = document.querySelector('.aside .vintage-list');

		if(carousel && vintages) {

			let vintagesSwiper = null;
			let mainSwiper = null;

			if(!vintages.swiper) {

				vintagesSwiper = new Swiper(vintages, {
					modules: [Controller, Navigation],
					direction: 'horizontal',
					speed: Durations.animations.ms,
					loop: false,
					observer: true,

					//autoplay: true,
					//observeParents: true,
					grabCursor: true,
					watchSlidesProgress: true,
					centerInsufficientSlides: true,
					slidesPerView: 'auto',
					draggable: true,

					// navigation: {
					// 	nextEl: this.querySelector('.swiper-button-next'),
					// 	prevEl: this.querySelector('.swiper-button-prev'),
					// },

					breakpoints: {
						1024: {
							direction: 'vertical',
						}
					},

					on: {
						afterInit: swiper => {
							$(swiper.el).find('.swiper-slide:first-child').addClass(Classes.active);
							$(swiper.el).find('.swiper-slide').each(function(index) {

								$(this).on('click', () => {
									$(swiper.el).find('.swiper-slide').removeClass(Classes.active);
									$(this).addClass(Classes.active);

									if(mainSwiper) {
										mainSwiper.slideTo(index);
									}
								});
							});
						}
					}
				});
			}

			if(!carousel.swiper) {

				mainSwiper = new Swiper(carousel, {
					speed: Durations.animations.ms * 1.5,
					loop: false,
					observer: true,

					modules: [Navigation, Pagination, Controller],
					breakpoints: {
						1024: {
							allowTouchMove: false,
						},
					},

					fadeEffect: {
						crossFade: true,
					},
					navigation: {
						prevEl: document.querySelector('.swiper-button-prev'),
						nextEl: document.querySelector('.swiper-button-next'),
					},
					on: {
						resize: function() {
							ScrollTrigger.refresh();
						},
						activeIndexChange: swiper => {

							if(vintagesSwiper) {
								$(vintagesSwiper.el).find('.swiper-slide').removeClass(Classes.active);
								$(vintagesSwiper.el).find('.swiper-slide:nth-child(' + (swiper.activeIndex + 1) + ')').addClass(Classes.active);
							}
						}
					}
				});
			}

			if(mainSwiper && vintagesSwiper) {
				//		mainSwiper.controller.control = vintagesSwiper;
				//		vintagesSwiper.controller.control = mainSwiper;
			}
		}

		//Background
		const backgroundNumber = document.querySelectorAll('.background p');

		backgroundNumber.forEach((number) => {
			const numberString = number.textContent;
			const parent = number.parentNode;

			parent.removeChild(number);

			numberString.split('').forEach((char) => {
				const splitNumber = document.createElement('p');
				splitNumber.textContent = char;
				parent.appendChild(splitNumber);
			});
		});

	});


	// ---------- Slideshow ----------

	$('.content-section.section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				autoplay: true,
				effect: 'fade',
				modules: [Autoplay, EffectFade, Navigation, Pagination],
				fadeEffect: {
					crossFade: true,
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Filter dates ----------

	const buttonFamousDate = document.getElementById('famousDate');
	const buttonAllDate = document.getElementById('allDate');
	const otherDates = document.querySelectorAll('.date-posts-section .text-column:not(.famous-date)');
	const famousDates = document.querySelectorAll('.date-posts-section .text-column.famous-date');
	const dateSection = document.querySelector('.date-posts-section');

	function resetAndRestartAnimations() {
		if(dateSection) {
			if(dateSection.classList.contains('active')) {
				dateSection.classList.remove('active');
			}

			if(dateSection.classList.contains('line-active')) {
				dateSection.classList.remove('line-active');
			}

			dateSection.style.setProperty('--scale-y', 0);

			dateSection.querySelectorAll('.text-column').forEach((column) => {
				if(column.classList.contains('line-active')) {
					column.classList.remove('line-active');
				}
			});

			dateSection.querySelectorAll('.text-column .column-content').forEach((column) => {
				if(column.classList.contains('active')) {
					column.classList.remove('active');
				}
			});

			ScrollTrigger.refresh();
			dateAnimation(dateSection);
		}
	}

	if(buttonFamousDate) {
		buttonFamousDate.addEventListener('click', (e) => {
			e.preventDefault();

			if(famousDates.length >= 1) {
				famousDates.forEach((date) => {
					if(!date.classList.contains('active-column')) {
						date.classList.add('active-column');
					}
				});
			}

			if(!buttonFamousDate.classList.contains('active')) {
				buttonFamousDate.classList.add('active');
			}

			if(buttonAllDate.classList.contains('active')) {
				buttonAllDate.classList.remove('active');
			}

			if(otherDates.length >= 1) {
				otherDates.forEach((date) => {
					if(!date.classList.contains('hidden')) {
						date.classList.add('hidden');
					}
				});
			}

			resetAndRestartAnimations()
		});
	}

	if(buttonAllDate) {
		buttonAllDate.addEventListener('click', (e) => {
			e.preventDefault();

			if(!buttonAllDate.classList.contains('active')) {
				buttonAllDate.classList.add('active');
			}

			if(buttonFamousDate.classList.contains('active')) {
				buttonFamousDate.classList.remove('active');
			}

			if(famousDates.length >= 1) {
				famousDates.forEach((date) => {
					if(date.classList.contains('active-column')) {
						date.classList.remove('active-column');
					}
				});
			}

			if(otherDates.length >= 1) {
				otherDates.forEach((date) => {
					if(date.classList.contains('hidden')) {
						date.classList.remove('hidden');
					}
				});
			}
			resetAndRestartAnimations()
		});
	}

	// ---------- Map Section ----------

	$('.map-section').each(function() {

		let mapContainer = this.querySelector('.map-container'),
			mapContainerX = gsap.quickSetter(mapContainer, 'x', 'px'),
			mapContainerY = gsap.quickSetter(mapContainer, 'y', 'px'),
			miniMap = this.querySelector('.mini-map'),
			miniMapMarker = this.querySelector('.mini-map-marker'),
			miniMapMarkerX = gsap.quickSetter(miniMapMarker, 'x', 'px'),
			miniMapMarkerY = gsap.quickSetter(miniMapMarker, 'y', 'px'),
			imageScale;

		const setupSizing = () => {

			imageScale = miniMap.offsetWidth / mapContainer.offsetWidth;

			let screenToBigRatio = window.innerWidth / mapContainer.offsetWidth,
				aspectRatio = window.innerWidth / window.innerHeight;

			gsap.set(miniMapMarker, {
				width: screenToBigRatio * miniMap.offsetWidth,
				height: screenToBigRatio * miniMap.offsetWidth / aspectRatio
			});
		};

		const alignMiniMapMarker = () => {
			miniMapMarkerX(-mapContainerDraggable.x * imageScale);
			miniMapMarkerY(-mapContainerDraggable.y * imageScale);
		};

		const alignMapContainer = () => {
			mapContainerX(-miniMapMarkerDraggable.x / imageScale);
			mapContainerY(-miniMapMarkerDraggable.y / imageScale);
		};

		const initMap = () => {
			setupSizing();
			mapContainerDraggable.update();
			alignMiniMapMarker();
		};

		let miniMapMarkerDraggable = Draggable.create(miniMapMarker, {
			bounds: miniMap,
			onDrag: alignMapContainer,
			onThrowUpdate: alignMapContainer,
			inertia: true
		})[0];

		let mapContainerDraggable = Draggable.create(mapContainer, {
			bounds: window,
			onDrag: alignMiniMapMarker,
			onThrowUpdate: alignMiniMapMarker,
			inertia: true
		})[0];

		gsap.set(mapContainer, {
			x: (mapContainerDraggable.minX + mapContainerDraggable.maxX) / 2,
			y: (mapContainerDraggable.minY + mapContainerDraggable.maxY) / 2
		});

		window.addEventListener('resize', initMap);
		initMap();
	});


	// ---------- Glightbox to members ----------
	$('.equipe-section').each(function() {

		const lightbox = GLightbox({
			selector: '.glightbox a',
			touchNavigation: true,
			loop: false,
			zoomable: true,
			inline: {
				allowFullscreen: false
			}
		});

		const lightboxLinks = document.querySelectorAll('.glightbox a');

		lightboxLinks.forEach(function(link) {
			link.addEventListener('click', function(event) {
				const container = document.querySelector('.gcontainer');
				if(container) {
					container.setAttribute('data-lenis-prevent', '');
				}

				const buttonClose = document.querySelector('.gclose');
				if(buttonClose) {
					buttonClose.innerHTML += 'Fermer';
				}
			});
		});
	})

});

import $ from 'jquery';

import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	function refreshPosts(form, button) {
		const page = form.find('input[name=page]');
		const filters = form.find('input[type=checkbox], input[type=radio], select');
		filters.prop('checked', false);
		filters.prop('selectedIndex', 0);

		button.checked = true;

		form.data('append', false);
		page.val(1);
		form.submit();
	}

	function activeLabel(labelActive, labels) {
		labels.forEach((item) => {
			if(item.classList.contains('active')) {
				item.classList.remove(('active'))
			}
		})

		labelActive.classList.add('active');
	}

	// ---------- Ajax Posts ----------

	if(typeof ajax_posts_params !== 'undefined') {

		$('form.ajax-posts-form').each(function() {

			const form = $(this);
			const button = form.find('button[type=submit]');
			const page = form.find('input[name=page]');
			const filters = form.find('.ajax-posts-filter');
			const content = form.find('.ajax-posts');
			const noResults = form.find('.no-results');
			const buttonAllCategories = document.getElementById('all-categories');
			const labels = document.querySelectorAll('.posts-filters .btn-link');

			button.on('click', () => form.data('append', true));

			buttonAllCategories.addEventListener('click', () => {
				refreshPosts(form, buttonAllCategories);
				activeLabel(buttonAllCategories.parentNode, labels)
			})

			filters.on('change', (e) => {
				const labelActive = e.currentTarget.parentNode;

				activeLabel(labelActive, labels)

				form.data('append', false);
				page.val(1);
				form.submit();
			});

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const form_data = new FormData(form[0]);
				form_data.append('action', ajax_posts_params.action);

				try {

					const response = await ajax({
						url: ajax_posts_params.admin_url,
						type: 'POST',
						dataType: 'json',
						data: form_data,
						processData: false,
						contentType: false,
					});

					if(response.data.content) {
						noResults.addClass(Classes.hidden);

						if(!form.data('append')) {
							content.empty();
						}

						content.append($(response.data.content));
						content.removeClass(Classes.hidden);
					}
					else {
						content.addClass(Classes.hidden);
						noResults.removeClass(Classes.hidden);
					}

					if(response.data.next_page === 0) {
						button.addClass(Classes.hidden);
						button.attr('disabled', true);
					}
					else {
						page.val(response.data.next_page);
						button.removeClass(Classes.hidden);
					}
				}
				catch(e) {
					content.addClass(Classes.hidden);
					noResults.removeClass(Classes.hidden);
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});
		});
	}
});
